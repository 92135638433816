import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"

import TopQuote from "../components/Promenade/TopQuote"
import Hero from "../components/Promenade/Hero"
import Logos from "../components/Promenade/Logos"
import Gallery from "../components/Promenade/Gallery"

const Promenade = props => {
  const { seoInfo, topQuote, heroSim, logos, gallery } = props.data
  const location = props.location.pathname
  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={props.location.pathname}
      />
      <TopQuote topQuote={topQuote} />
      <Hero hero={heroSim} />
      <Logos logos={logos} />
      <Gallery gallery={gallery} />
    </Layout>
  )
}

export const promenadeQuery = graphql`
  query promenadePage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    topQuote: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_toqu_title
        _coo_toqu_content
      }
    }

    heroSim: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_hosim_bg {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_hosim_content
        _cco_hosim_button_required
        _coo_hosim_btn_text
        _coo_hosim_page_link_two
      }
    }
    logos: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_lodis_title
        _coo_lodis_logos {
          logo {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          link
        }
        _coo_lodis_ending_content
        _coo_lodis_ronmor_logo {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_lodis_ronmor_content
      }
    }

    gallery: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_galimg_gallery {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default Promenade
