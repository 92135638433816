import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { colors, medWrapper, fontSizer } from "../../styles/helpers"

import GallerySlider from "./GallerySlider"

const GallerySection = styled.section`
  padding-top: 1rem;
  padding-bottom: 5rem;

  @media (min-width: 768px) {
    padding-top: 5rem;
  }

  .wrapper {
    ${medWrapper};
  }

  .galleyWrap {
    columns: 2 400px;

    @media (min-width: 768px) {
      columns: 2 350px;
      column-gap: 1rem;
    }

    @media (min-width: 1025px) {
      columns: 2 500px;
      column-gap: 1rem;
    }
  }

  .galleryImage {
    position: relative;
    display: inline-block;
    width: 100%;
    margin: 0 1rem 1rem 0;
    cursor: pointer;

    &__image {
      width: 100%;
      transition: all 0.4s ease-out;
      overflow: hidden;

      &--wrap {
        display: block;
        transform: scale(1);
        transition: all 0.4s ease-out;
      }
    }

    &__hoverState {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all 0.4s ease-out;
      background-color: rgba(0, 134, 117, 0.6);
      opacity: 0;

      p {
        ${fontSizer(5, 10, 76.8, 150, 2.6)};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        color: ${colors.white};
      }
    }
    &:hover {
      .galleryImage__image {
        .galleryImage__image--wrap {
          transform: scale(1.1);
        }
      }
      .galleryImage__hoverState {
        opacity: 0.99;
      }
    }
  }

  @for $i from 1 through 36 {
    &:nth-child(#{$i}) {
      $h: (random(400) + 100) + px;
      height: $h;
    }
  }
`

const Gallery = ({ gallery }) => {
  const [isSliderActive, setIsSliderActive] = useState(false)
  const [clikcedImage, setClikcedImage] = useState(0)
  const images = gallery.acf._coo_galimg_gallery

  return (
    <GallerySection>
      <div className="wrapper">
        <div className="galleyWrap">
          {images.map((img, index) => {
            return (
              <div
                onClick={() => {
                  setIsSliderActive(true)
                  setClikcedImage(index)
                }}
                className="galleryImage"
                key={index}
              >
                <div className="galleryImage__image">
                  <div className="galleryImage__image--wrap">
                    {img?.localFile && (
                      <Img
                        fluid={img?.localFile?.childImageSharp?.fluid}
                        alt={img?.alt_text}
                      />
                    )}
                  </div>
                </div>
                <div className="galleryImage__hoverState">
                  <p>&#43;</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {isSliderActive && (
        <GallerySlider
          images={images}
          setIsSliderActive={setIsSliderActive}
          clikcedImage={clikcedImage}
        />
      )}
    </GallerySection>
  )
}

export default Gallery
