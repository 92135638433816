import React, { useEffect, useRef } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { H1Green, B1Black, medWrapper } from "../../styles/helpers"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const LogosSection = styled.section`
  padding: 3rem 0;

  .wrapper {
    ${medWrapper};
  }

  .title {
    width: 100%;
    text-align: center;

    h2 {
      ${H1Green};
    }
  }

  .logos {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    .logo {
      align-self: center;
      width: calc(33.33% - 1rem);
      margin: 0.5rem;
      padding: 0.5rem;

      @media (min-width: 768px) {
        width: calc(20% - 2rem);
        margin: 0.5rem 1rem;
        padding: 1rem;
      }

      @media (min-width: 1025px) {
        width: calc(16.66% - 2rem);
        margin: 2rem 1rem;
        padding: 1rem;
      }
    }
  }

  .content {
    padding-top: 5rem;
    text-align: center;

    p {
      ${B1Black};
    }
  }

  .ronmorLogo {
    width: 100%;
    margin-top: 5rem;

    &__logowrap {
      max-width: 30rem;
      margin: auto;
    }

    &__content {
      max-width: 50rem;
      margin: 2.5rem auto 0;

      p {
        ${B1Black};
      }
    }
  }
`

const Logos = ({ logos }) => {
  const title = logos.acf._coo_lodis_title
  const logosImgs = logos.acf._coo_lodis_logos
  const content = logos.acf._coo_lodis_ending_content
  const wrapEle = useRef(null)

  useEffect(() => {
    const logos = [...wrapEle.current.querySelectorAll(".logo")]
    gsap.set(logos, { autoAlpha: 0, y: 200, ease: "slow(0.7, 0.7, false)" })
    gsap.to(logos, {
      autoAlpha: 1,
      y: 0,
      ease: "power3.out",
      duration: 3,
      stagger: {
        amount: 3.5,
      },
      scrollTrigger: {
        trigger: wrapEle.current,
        markers: false,
        start: "top 65%",
        end: "bottom 0%",
        toggleActions: "play none none none",
      },
    })
  }, [])

  return (
    <LogosSection>
      <div className="wrapper">
        <div className="title">
          <h2>{title}</h2>
        </div>
        <div ref={wrapEle} className="logos">
          {logosImgs.map((logo, index) => {
            return (
              <div className="logo" key={index}>
                <a href={logo.link} target="_blank" rel="noopener noreferrer">
                  <Img
                    fluid={logo.logo.localFile.childImageSharp.fluid}
                    alt={logo.logo.alt_text}
                  />
                </a>
              </div>
            )
          })}
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <div className="ronmorLogo">
          <div className="ronmorLogo__logowrap">
            <Img
              fluid={
                logos.acf._coo_lodis_ronmor_logo.localFile.childImageSharp.fluid
              }
              alt={logos.acf._coo_lodis_ronmor_logo.alt_text}
            />
          </div>
          <div
            className="ronmorLogo__content"
            dangerouslySetInnerHTML={{
              __html: logos.acf._coo_lodis_ronmor_content,
            }}
          />
        </div>
      </div>
    </LogosSection>
  )
}

export default Logos
